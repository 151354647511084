import React, { Dispatch, FC, SetStateAction, useState } from "react"
import { Add32 } from "@carbon/icons-react"
import { DataFrame, ISeries } from "data-forge"
import { readAndUploadFile } from "../../utils/fileHandler"
import Layout from "../../components/layout"
import { ITypeFrequency } from "data-forge/build/lib/dataframe"
import Module from "../../components/module/module"
import SEO from "../../components/seo"
import { Link } from "gatsby"

const getFileSize = (number: number) => {
  if (number < 1024) {
    return number + "bytes"
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + "KB"
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + "MB"
  } else {
    return "0"
  }
}

const DataFrameInfoContainer: FC<{
  df: DataFrame
  setActiveColumn: Dispatch<SetStateAction<ISeries<number, any>>>
  setColumnData: Dispatch<SetStateAction<ITypeFrequency>>
  toggle: any
}> = ({ df, setActiveColumn, setColumnData, toggle }) => {
  const types = df.detectTypes().toJSON()
  const columnData: ITypeFrequency[] = JSON.parse(types)
  return (
    <div className="max-h-64" style={{ maxHeight: "16rem" }}>
      <nav className="w-full flex px-4 py-1 justify-center">
        <span
          onClick={() => toggle(true)}
          className="mx-4 hover:bg-gray-200 hover:border-b-4 cursor-not-allowed  hover:border-light-green px-2"
        >
          Table
        </span>
        <span className="mx-4 border-b-4 border-light-green cursor-pointer">
          Column Info
        </span>
        <span
          onClick={() => toggle(true)}
          className="mx-4 hover:bg-gray-200 hover:border-b-4 cursor-not-allowed  hover:border-light-green px-2"
        >
          Chart
        </span>
      </nav>
      <table className="font-mono m-0">
        <tr>
          <th>Column Name</th>
          <th>Type</th>
          <th>Frequency</th>
        </tr>
        <tbody>
          {columnData.map(column => (
            <tr
              className="hover:bg-gray-100 cursor-pointer focus:bg-gray-400 text-xs "
              key={column.Column}
              onClick={() => {
                const serie = df.getSeries(column.Column)
                setActiveColumn(serie)
                setColumnData(column)
              }}
            >
              <td>{column.Column}</td>
              <td>{column.Type}</td>
              <td>{column.Frequency}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const DataFrameStatsContainer: FC<{ serie: ISeries; col: ITypeFrequency }> = ({
  serie,
  col,
}) => {
  if (!serie) {
    return (
      <div className="w-1/2">
        <p className="text-sm m-4">
          Click on a column to view more info about it.
        </p>
      </div>
    )
  }
  return (
    <div className="w-1/2 overflow-auto">
      <h3 className="m-0 text-center mt-1 font-mono underline pb-1">
        {col.Column}
      </h3>
      <ul className="m-0 px-2 text-xs">
        {col.Type === "number" && (
          <>
            <li>
              Type:
              <code>{col.Type}</code>
            </li>
            <li>
              Average <code>{serie.average()}</code>
            </li>
            <li>
              Median <code>{serie.median()}</code>
            </li>
            <li>
              Min <code>{serie.min()}</code>
            </li>
            <li>
              Max <code>{serie.max()}</code>
            </li>
            <li>
              Std Deviation <code>{serie.std()}</code>
            </li>
            <li>
              Count <code>{serie.count()}</code>
            </li>
            <li>
              Unique <code>{serie.distinct().count()}</code>
            </li>
          </>
        )}
        {col.Type === "boolean" && (
          <>
            <li>
              Type: <code>{col.Type}</code>
            </li>
            <li>
              Count <code>{serie.count()}</code>
            </li>
            <li>
              Unique <code>{serie.distinct().count()}</code>
            </li>
            <li>
              Most Frequent{" "}
              <code>
                {
                  serie
                    .distinct()
                    .detectValues()
                    .orderByDescending(val => val.Frequency)
                    .first().Value
                }
              </code>
            </li>
            <li>
              Frequency{" "}
              <code>
                {serie
                  .distinct()
                  .detectValues()
                  .orderByDescending(val => val.Frequency)
                  .first()
                  .Frequency.toFixed(2)}
                %
              </code>
            </li>
          </>
        )}
        {col.Type === "string" && (
          <>
            <li>
              Type: <code>{col.Type}</code>
            </li>
            <li>
              Count <code>{serie.count()}</code>
            </li>
            <li>
              Unique <code>{serie.distinct().count()}</code>
            </li>
            <li>
              Most Frequent{" "}
              <code>
                {
                  serie
                    .distinct()
                    .detectValues()
                    .orderByDescending(val => val.Frequency)
                    .first().Value
                }
              </code>
            </li>
            <li>
              Frequency{" "}
              <code>
                {serie
                  .distinct()
                  .detectValues()
                  .orderByDescending(val => val.Frequency)
                  .first()
                  .Frequency.toFixed(2)}
                %
              </code>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}

const FileInfoPage = () => {
  const [df, setDf] = useState<DataFrame>()
  const [file, setFile] = useState<File>()
  const [activeColumn, setActiveColumn] = useState<ISeries>()
  const [columnData, setColumnData] = useState<ITypeFrequency>()

  const [info, toggleInfo] = useState(true)

  const [isVisible, toggle] = useState(false)

  const handleFileUpload = async () => {
    const frame = await readAndUploadFile(file)
    setDf(frame)
  }

  return (
    <Layout>
      <SEO
        title="CVS File Analyser"
        description="Analyse your excel and csv files and learn about its structure and composition. A perfect first step into more data driven decision making."
      />
      <div>
        <h1 className="text-center text-green">File Info</h1>
        <p className="w-2/3 mx-auto mb-0">
          <span
            onClick={() => toggleInfo(!info)}
            className="cursor-pointer bg-light-blue hover:shadow-sm border-light-blue hover:text-black text-sm border-4 text-white py-1 px-2 rounded"
          >
            {info ? `Hide` : `Show`} Description
          </span>
        </p>
        {info && (
          <div className="border-b-2 pt-2 w-2/3 mx-auto">
            <p className="w-4/5 md:w-2/3 mx-auto">
              Use this tool to quickly view information about your file (such as
              column statistics and frequency). To get started upload a{" "}
              <code>CSV</code> file. If you have an excel file first open them
              in excel and save or export it as csv, after that you can open
              that file with the <i>cell</i> below.
              {/* (read how to turn your excel files into csv
          files{" "}
          <a href="/" className="hover:text-green underline cursor-pointer">
          here
          {/*</a>*/}
              .{" "}
              <span className="italic text-sm">
                NOTE: files larger than 20mb can cause performance issues if
                they contain to many data points.
              </span>
            </p>
            <p className="w-4/5 md:w-2/3 mx-auto">
              Once uploaded you can view each column of that file and click on
              it to view futher information about it. More statistics will be
              added with time so check back occasinally to gain even more
              insights.
            </p>
          </div>
        )}
      </div>
      <div className="font-sans scrollbar-custom cell-container bg-light-green border-2 max-h-48 md:w-2/3 w-3/4 my-4 mx-auto overflow-hidden flex flex-col md:flex-row">
        <div className="data-view bg-white overflow-auto relative w-full md:w-4/5">
          {df ? (
            <DataFrameInfoContainer
              df={df}
              setActiveColumn={setActiveColumn}
              setColumnData={setColumnData}
              toggle={toggle}
            />
          ) : file ? (
            <>
              <ul className="w-2/3 mx-auto my-4">
                <li>
                  Name:{" "}
                  <span className="font-mono border-b-2">{file?.name}</span>
                </li>
                <li>
                  Last Modified:{" "}
                  <span className="font-mono border-b-2">
                    {new Date(file?.lastModified).toDateString()}
                  </span>
                </li>
                <li>
                  Size:{" "}
                  <span className="font-mono border-b-2">
                    {getFileSize(file.size)}
                  </span>
                </li>
              </ul>
              <div className="flex justify-center pb-4">
                <button
                  className="rounded bg-light-green p-1 px-2 mx-2 hover:bg-dark-green hover:text-white"
                  onClick={handleFileUpload}
                >
                  View File
                </button>
                <button
                  className="rounded bg-white p-1 hover:border-2 mx-2 hover:bg-gray-200 hover:border-black"
                  onClick={() => setFile(undefined)}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <form className="mx-auto py-12 w-2/3 h-2/3 my-auto mx-auto">
              <label className="block mx-auto text-center text-xl pb-2">
                <span className="font-mono">CSV</span> file
              </label>
              <input
                type="file"
                accept=".csv"
                onChange={e => {
                  if (e.target.files) {
                    setFile(e.target.files[0])
                  }
                }}
              />
            </form>
          )}
        </div>
        {df && (
          <DataFrameStatsContainer serie={activeColumn} col={columnData} />
        )}
      </div>
      <div className="w-full text-center text-gray-700 font-sans text-sm">
        <Add32
          className="h-10 w-10 mx-auto rounded-full hover:shadow-custom cursor-not-allowed"
          onClick={() => toggle(true)}
        />
        Add Cell
      </div>
      <Module visible={isVisible}>
        <h3 className="text-center mt-6">Discovered a Premium Feature!</h3>
        <p className="px-8 m-0">
          To use this feature (and many more) you need an active explot
          subscription.
        </p>
        <div className="absolute bottom-0 w-full h-16 flex justify-around pb-4">
          <Link
            className="gradient w-2/5 flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            to="/buy/"
          >
            Buy Now
          </Link>
          <button
            onClick={() => toggle(false)}
            className="w-2/5 flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green bg-indigo-100 hover:text-blue hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
          >
            Close
          </button>
        </div>
      </Module>
    </Layout>
  )
}

export default FileInfoPage
