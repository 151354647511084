import { Link } from "gatsby"
import React, { useState } from "react"

const Module = ({ children, visible }) => {
  if (visible) {
    return (
      <div className="absolute top-0 w-full h-full bg-light-green bg-opacity-75">
        <div className="absolute top-0 md:ml-64 h-64 mt-16 md:mt-24 shadow-custom w-full md:w-1/3 bg-white">
          {children}
        </div>
      </div>
    )
  }

  return null
}

export default Module
